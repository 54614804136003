import { NgModule, PLATFORM_ID } from '@angular/core';
import { APOLLO_FLAGS, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloLink, InMemoryCache, Observable, createHttpLink, split } from '@apollo/client/core';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { HttpLink } from 'apollo-angular/http';
import { HttpClientModule } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { Router } from '@angular/router';
import { getAccessToken, setAccessToken } from './entry/auth/login/auth.utils';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getSnackBarConfig } from './utils/material.utils';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { RefreshService } from './entry/refresh.service';
import { firstValueFrom } from 'rxjs';
import { setContext } from '@apollo/client/link/context';
import { ErrorCode } from './shared/constants';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from './services/storage.service';

// const apollo: Apollo;
// const uri = 'http://localhost:3000/graphql'; // <-- add the URL of the GraphQL server here
const apiOrigin = environment.apiOrigin;
let routerRef: Router;

export function createApollo(
  httpLink: HttpLink,
  snackBar: MatSnackBar,
  refreshService: RefreshService,
  storageService: StorageService,
  platformId: string
): any {
  const authLink = setContext((operation, context) => {
    const token = getAccessToken(storageService);

    if (token) {
      return {
        headers: {
          ...context.headers,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };
    }

    return context;
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions.errorCode === ErrorCode.UNAUTHORIZED) {
          return handleUnAuthError(storageService, refreshService, operation, forward);
        }
        // Handle specific GraphQL errors...
        handleEventPrivateError(err.extensions.errorCode, snackBar, routerRef);
        handleEventNotFoundError(err.extensions.errorCode, snackBar, routerRef);
        handleForbiddenError(err.message, snackBar);

        handleTokenError(storageService, err.extensions.errorCode, routerRef);
      }
    }
    if (networkError) {
      handleNetworkError(networkError, snackBar);
    }
    return forward(operation);
  });

  // HTTP connection to the API
  const http = createHttpLink({
    // For production you should use an absolute URL here
    uri: `https://${apiOrigin}/graphql`,
    credentials: 'include'
  });
  // Create the subscription websocket link
  // ws: for regular and wss: for secured
  // localhost:3000 - same as http because of GraphQL module option installSubscriptionHandlers
  const wsLink = isPlatformBrowser(platformId)
    ? new GraphQLWsLink(
        createClient({
          url: `wss://${apiOrigin}/graphqlws`,
          lazy: true,
          connectionParams: () => {
            const token = getAccessToken(storageService);
            return {
              Authorization: token ? `Bearer ${token}` : ''
            };
          },
          on: {
            connected: () => console.log('ws connected'),
            closed: () => console.log('ws closed')
          }
        })
      )
    : null;

  const linkSplit =
    wsLink != null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
          },
          wsLink, // Send subscription traffic to websocket link
          http // All other traffic to http link
        )
      : http;

  const link = ApolloLink.from([errorLink, authLink, linkSplit]);

  const cache = new InMemoryCache();

  if (isPlatformBrowser(platformId)) {
    persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
      key: `apollo-cache-${environment.cacheVersion}`
    });
  }

  return {
    link,
    cache,
    connectToDevTools: environment.production ? false : true,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      mutate: {
        fetchPolicy: 'no-cache'
      },
      subscription: {
        fetchPolicy: 'no-cache'
      }
    }
  };
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true // enable it here
      }
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, MatSnackBar, RefreshService, StorageService, PLATFORM_ID]
    }
  ]
})
export class GraphQLModule {
  constructor(router: Router) {
    routerRef = router;
  }
}

const handleEventPrivateError = (err: unknown, snackBar: MatSnackBar, router: Router): void => {
  if (err === ErrorCode.EVENT_IS_PRIVATE) {
    handleSnackBar($localize`Event spot canceled`, router, snackBar);
  }
};

const handleEventNotFoundError = (err: unknown, snackBar: MatSnackBar, router: Router): void => {
  if (err === ErrorCode.EVENT_NOT_FOUND) {
    handleSnackBar($localize`Event is deleted`, router, snackBar);
  }
};

const handleForbiddenError = (err: unknown, snackBar: MatSnackBar): void => {
  if (err === ErrorCode.FORBIDDEN_EXCEPTION) {
    snackBar.open(
      $localize`Forbidden: You do not have permission to access this resource.`,
      $localize`:@@close:Close`,
      getSnackBarConfig()
    );
  }
};

const handleUnAuthError = (
  storageService: StorageService,
  refreshService: RefreshService,
  operation: any,
  forward: any
): Observable<any> | void => {
  if (getAccessToken(storageService)) {
    return new Observable((observer) => {
      firstValueFrom(refreshService.fetchAccessToken())
        .then((refreshResponse) => {
          const newAccessToken = refreshResponse;
          operation.setContext(({ headers = {} }) => ({
            headers: {
              ...headers,
              authorization: newAccessToken ? `Bearer ${newAccessToken}` : ''
            }
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          };
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          // Handle token refresh error
          observer.error(error);
          setAccessToken(storageService, '');
          routerRef.navigate(['login']);
        });
    });
  } else {
    setAccessToken(storageService, '');
    routerRef.navigate(['login']);
  }
};

const handleTokenError = (storageService: StorageService, err: unknown, router: Router): void => {
  if (err === ErrorCode.TOKEN_IS_EXPIRED || err === ErrorCode.JWT_MUST_BE_PROVIDED) {
    setAccessToken(storageService, '');
    router.navigate(['login']);
  }
};

const handleNetworkError = (networkError: Error, snackBar: MatSnackBar) => {
  console.error('Network error:', networkError);
  snackBar.open(
    $localize`Network error. Please check your connection to fully use the app`,
    $localize`:@@close:Close`,
    getSnackBarConfig()
  );
};

const handleSnackBar = (message: string, routerRef: Router, snackBar: MatSnackBar) => {
  routerRef.navigate(['']);
  snackBar.open(message, $localize`:@@close:Close`, getSnackBarConfig());
};
