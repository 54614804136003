import { Inject, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HammerModule } from '@angular/platform-browser';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { MyHammerConfig } from '../utils/common.utils';

const moduleList = [
  MatIconModule,
  MatButtonModule,
  MatTableModule,
  MatInputModule,
  MatPaginatorModule,
  MatSnackBarModule,
  MatCardModule,
  MatToolbarModule,
  MatTabsModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatCheckboxModule,
  NgxMatTimepickerModule,
  DragDropModule,
  MatBadgeModule,
  MatDialogModule,
  MatAutocompleteModule,
  HammerModule,
  MatExpansionModule,
  MatChipsModule,
  MatTooltipModule,
  ReactiveFormsModule
];

@NgModule({
  imports: moduleList,
  exports: moduleList,
  providers: isPlatformBrowser(PLATFORM_ID) ? [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ] : []
})
export class SharedModule {
  constructor(@Inject(LOCALE_ID) public locale: string) {
    NgxMatTimepickerModule.setLocale(this.locale);
  }
}
