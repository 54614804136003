import { Component, DestroyRef, Inject, LOCALE_ID, OnInit, WritableSignal, signal } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { GeoSettingComponent } from './modals/geo-setting/geo-setting.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ColorSchemeService } from './services/theme-color-scheme.service';
import { ConfigService } from './services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getSnackBarConfig } from './utils/material.utils';
import { Event, NavigationEnd, Router } from '@angular/router';
import { RefreshService } from './entry/refresh.service';
import { ProfileService } from './core/profile/profile.service';
import { CookieService } from './services/cookie.service';
import { setLangCookies } from './utils/profile.utils';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from './services/storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { reloadInPWA } from './utils/common.utils';

@Component({
  selector: 'hb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isUkraineUser: WritableSignal<boolean> = signal(true);
  public loadMain = false;
  private localLang!: string;
  private currentScreenSize = 'Unknown';
  // Create a map to display breakpoint names for demonstration purposes.
  private displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge']
  ]);

  constructor(
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(LOCALE_ID) public locale: string,
    private _adapter: DateAdapter<Date>,
    private themeColorService: ColorSchemeService,
    private configService: ConfigService,
    private router: Router,
    private snackBar: MatSnackBar,
    private refreshService: RefreshService,
    private profileService: ProfileService,
    private cookieService: CookieService,
    private http: HttpClient,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {
    this.themeColorService.load();
    // Get Local Language
    this.localLang = locale?.split('-')[0].toString();

    this._adapter.setLocale(locale);

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
  }

  public ngOnInit(): void {
    this.registerIcons();
    this.isUserFromUkraine();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Check the current route URL
        const currentRoute = event.url;

        if (
          !this.storageService.getValue("store-language") &&
          !currentRoute.includes('network-error') &&
          !currentRoute.includes('404') &&
          this.refreshService.isAuthenticated$.getValue()
        ) {
          this.profileService.getHobbies().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.openDialog();
          })
        } else {
          this.loadMain = true;
        }
      }
    });
    if (!this.storageService.getValue("store-showedWarning")) {
      this.storageService.setValue('store-showedWarning', 'true')
      this.snackBar.open(
        $localize`Let\'s keep our space respectful - avoid using offensive language, please`,
        $localize`:@@close:Close`,
        getSnackBarConfig()
      );
    }
  }

  public isUserFromUkraine(): void {
    const isFromUkraine = this.profileService.getUserPos();

    if(!isFromUkraine) {
      this.getCountry().subscribe(country => {
        this.profileService.updateUserPos(country === 'Ukraine');
        this.isUkraineUser.set(country === 'Ukraine');
      })
    } else {
      !this.isUkraineUser() && this.isUkraineUser.set(true);
    }
  }

  private getCountry(): Observable<string> {
    return this.http.get<any>(`https://api.ipregistry.co/?key=${environment.countryByIpApiKey}`).pipe(
      map(response => response.location.country.name),
      takeUntilDestroyed(this.destroyRef),
      catchError((error: any) => {
        console.error('getCountry', error);
        return 'Ukraine';
      })
    );
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(GeoSettingComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '600px',
      panelClass: 'hb-dialog-container'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async (lang) => {
        this.configService.notifyCityChange(this.storageService.getValue("store-city"));

        if (lang) {
          let country = 'GB';
          if (lang === 'uk') {
            country = 'UA';
          }

          setLangCookies(this.cookieService, country, lang);

          await this.router.navigateByUrl('/');
          reloadInPWA();
        }
        this.loadMain = true;
      });
  }

  // ToDo: move to Service shared icons or to proper components if icons are not shared
  private registerIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'account_box',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/account_box.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'globe',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/globe.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/dashboard.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'setup',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/setup.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'location',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/location.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'call',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/call.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'home',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/home.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'email',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/email.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/phone.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/instagram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'location-blue',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/location-blue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tree',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/tree.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'football',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/football.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/twitter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bookmark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/bookmark_border.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bookmark-add',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/bookmark_add.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'send',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/send.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/edit.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'notification',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/notification.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron_down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/chevron_down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'chevron_up',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/chevron_up.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'search',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/search.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'done',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/done.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'logout',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/logout.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'telegram',
      this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/telegram.svg')
    );
  }
}
