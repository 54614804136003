import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SelectComponent } from '../../shared/select/select.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { GoogleplaceDirective } from 'src/app/shared/google-places.directive';
import { ConfigService } from 'src/app/services/config.service';
import { HobbyComponent } from 'src/app/shared/hobby';
import { ProfileService } from '../../core/profile/profile.service';
import { GooglePlace } from 'src/app/shared/model/googleplace.interface';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { FeatureFlagDirective } from 'src/app/shared/feature-flag.directive';
import { MatIconModule } from '@angular/material/icon';
import { GoogleMapsService } from 'src/app/services/google-maps.service';
import { getSnackBarConfig } from 'src/app/utils/material.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getCityName } from 'src/app/utils/profile.utils';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'hb-geo-setting',
  templateUrl: './geo-setting.component.html',
  styleUrls: ['./geo-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SelectComponent,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    GoogleplaceDirective,
    HobbyComponent,
    FeatureFlagDirective,
    MatIconModule
  ]
})
export class GeoSettingComponent implements OnInit {
  public geoSettingForm!: FormGroup;

  public languages = [
    {
      label: 'English',
      value: 'en'
    },
    {
      label: 'Ukrainian',
      value: 'uk'
    }
  ];

  private selectedLang = '';

  constructor(
    private profileService: ProfileService,
    private formBuilder: FormBuilder,
    private configService: ConfigService,
    private dialogRef: MatDialogRef<GeoSettingComponent>,
    private featureFlagService: FeatureFlagService,
    private googleMapsService: GoogleMapsService,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) {
    this.dialogRef.disableClose = true;
  }

  public ngOnInit(): void {
    const isMVP = this.featureFlagService.getFeature();
    this.selectedLang = this.storageService.getValue('store-language') || navigator.language?.split('-')[0] || 'en';

    this.geoSettingForm = this.formBuilder.group({
      city: [{ value: isMVP ? 'Львів' : '', disabled: isMVP }, Validators.required],
      lang: [this.selectedLang, Validators.required]
    });
    if (isMVP) {
      this.geoSettingForm.get('lang')!.valueChanges.subscribe((language) => {
        const cityName = language === 'en' ? 'Lviv' : 'Львів';
        this.geoSettingForm.get('city')!.setValue(cityName);
      });
    }
  }

  public onClose(): void {
    const profileObject = {
      ...(this.profileService.profileObj$.getValue() ?? {})
    };

    if (this.featureFlagService.getFeature() && this.geoSettingForm.get('city')?.value) {
      profileObject.city = this.geoSettingForm.get('city')?.value;
      this.storageService.setValue('store-cityName', this.geoSettingForm.get('city')?.value);
    }

    this.profileService.updateUser(profileObject).subscribe(() => {
      if (profileObject.hobbies) {
        this.profileService.updateSelectedHobbyItems(profileObject.hobbies);
      }

      let lang = this.geoSettingForm.get('lang')?.value;
      this.storageService.setValue('store-language', lang);

      if (this.selectedLang === lang) {
        lang = null;
      }

      this.dialogRef.close(lang);
    });
  }

  public setCityData(place: GooglePlace): void {
    this.configService.setCityOption(place);
  }

  public getCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (position) {
            this.googleMapsService
              .getCityName(position.coords.latitude, position.coords.longitude, this.geoSettingForm.get('lang')?.value)
              .subscribe((response) => {
                if (response?.results?.length) {
                  for (const component of response.results) {
                    if (component?.types?.includes('plus_code')) {
                      //To Do: save lat and lang
                      const { formatted_address, name } = getCityName(component.formatted_address);

                      this.geoSettingForm.get('city')?.setValue(formatted_address);

                      this.setCityNameAndPlaceId(formatted_address, name);
                    }
                  }
                }
              });
          }
        },
        () => {
          this.snackBar.open($localize`The Geolocation service failed`, 'Close', getSnackBarConfig());
        }
      );
    }
  }

  private setCityNameAndPlaceId(formatted_address: string, name: string): void {
    this.googleMapsService.getPlaceIdByCityName(formatted_address).subscribe((res) => {
      this.setCityData({ formatted_address, name, place_id: res.results[0].place_id });
    });
  }
}
