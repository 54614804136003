import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CoreComponent } from './core/core.component';
import { AuthGuard } from './entry/auth/auth.guard';
import { AdminGuard } from './core/admin/admin.guard';
import { FeatureFlagService } from './services/feature-flag.service';

export const featureFlagGuard = () => {
  return (): boolean => {
    const featureFlagService = inject(FeatureFlagService);
    return !featureFlagService.getFeature();
  };
};

const routes: Routes = [
  {
    path: '',
    component: CoreComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadComponent: () => import('./core/events').then((m) => m.EventsComponent),
        canActivate: featureFlagGuard() ? [AuthGuard] : []
      },
      {
        path: 'profile/:id',
        loadComponent: () => import('./core/profile').then((m) => m.ProfileComponent),
        canActivate: [AuthGuard]
      },
      {
        path: 'dashboard',
        loadComponent: () => import('./core/dashboard').then((m) => m.DashboardComponent),
        canActivate: [AuthGuard]
      },
      {
        path: 'setup',
        loadComponent: () => import('./core/setup').then((m) => m.SetupComponent)
      },
      {
        path: 'filter',
        loadComponent: () => import('./modals/filter-modal').then((m) => m.FilterModalComponent),
        canActivate: [featureFlagGuard()]
      },
      {
        path: 'position',
        loadComponent: () => import('./modals/position-modal').then((m) => m.PositionModalComponent),
        canActivate: [featureFlagGuard()]
      },
      {
        path: 'settings',
        loadComponent: () => import('./core/profile/settings').then((m) => m.SettingsComponent),
        canActivate: [featureFlagGuard()]
      },
      {
        path: 'admin',
        loadComponent: () => import('./core/admin').then((m) => m.AdminComponent),
        canActivate: [AuthGuard, AdminGuard]
      },
      {
        path: 'city',
        loadComponent: () => import('./core/city').then((m) => m.CityComponent),
        canActivate: [featureFlagGuard()]
      },
      {
        path: 'notification',
        loadComponent: () => import('./core/notifications').then((m) => m.NotificationComponent),
        canActivate: [AuthGuard, featureFlagGuard()]
      },
      {
        path: 'invite/:id',
        loadComponent: () => import('./core/invite').then((m) => m.InviteComponent),
        canActivate: [AuthGuard, featureFlagGuard()]
      },
      {
        path: 'map/:lat/:long',
        loadComponent: () => import('./core/events/map').then((m) => m.MapComponent)
      }
    ]
  },
  {
    path: 'event-details/:id',
    loadComponent: () => import('./core/events/event-details').then((m) => m.EventDetailsComponent)
  },
  {
    path: 'add-event',
    loadComponent: () => import('./core/events/create-event').then((m) => m.CreateEventComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-event/:id',
    loadComponent: () => import('./core/events/create-event').then((m) => m.CreateEventComponent),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadComponent: () => import('./entry/auth/login').then((m) => m.LoginComponent)
  },
  {
    path: 'network-error',
    loadComponent: () => import('./entry/network-error').then((m) => m.NetworkErrorComponent)
  },
  {
    path: '404',
    loadComponent: () => import('./entry/empty').then((m) => m.EmptyComponent)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
