import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CoreComponent } from './core';
import { DashboardComponent } from './core/dashboard';
import { HttpClientModule } from '@angular/common/http';
import { EmptyComponent } from './entry/empty';
import { SharedModule } from './shared/shared.module';
import { ProfileComponent } from './core/profile';
import { EventsComponent } from './core/events';
import { SetupComponent } from './core/setup';
import { GraphQLModule } from './graphql.module';
import { AuthModule } from './entry/auth/auth.module';
import { EventCardComponent } from './core/events/event-card';
import { EventDetailsComponent } from './core/events/event-details';
import { PlayerCardComponent } from './core/events/player-card';
import { InfoComponent } from './core/profile/info';
import { CreateEventComponent } from './core/events/create-event';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GeoSettingComponent } from './modals/geo-setting';
import { FilterModalComponent } from './modals/filter-modal';
import { PositionModalComponent } from './modals/position-modal';
import { SettingsComponent } from './core/profile/settings';
import { CityComponent } from './core/city';
import { LinkComponent } from './core/profile/info/links';
import { NewsComponent } from './core/news';
import { FieldComponent } from './core/fields';
import { NotificationComponent } from './core/notifications';
import { InviteComponent } from './core/invite';
import { NotificationDetailComponent } from './core/notifications/norification-detail';
import { MapComponent } from './core/events/map';
import { WeekDayPipe } from './pipe/weekday.pipe';
import { ApolloModule } from 'apollo-angular';
import { SelectComponent } from './shared/select';
import { DayPipe } from './pipe/dayPipe.pipe';
import { GoogleplaceDirective } from './shared/google-places.directive';
import { GroupModalComponent } from './modals/group-modal';
import { UpdateUserModalComponent } from './modals/update-user-modal';
import { FieldModalComponent } from './modals/field-modal';
import { AddFieldModalComponent } from './modals/add-field-modal';
import { FeatureFlagService } from './services/feature-flag.service';
import { Observable } from 'rxjs';
import { FeatureFlagDirective } from './shared/feature-flag.directive';
import { ButtonStateAnimationDirective } from './shared/button-animation.directive';
import { NetworkErrorComponent } from './entry/network-error';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
// import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';
import { RouterModule } from '@angular/router';

const initializeFeatureFlag = (featureFlagService: FeatureFlagService): (() => Observable<any>) => {
  return () => featureFlagService.getFeatureFlag();
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ApolloModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    ServiceWorkerModule.register('main-service.worker.js', { enabled: environment.production }),
    GraphQLModule,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    CoreComponent,
    DashboardComponent,
    EmptyComponent,
    NetworkErrorComponent,
    ProfileComponent,
    EventsComponent,
    SetupComponent,
    EventCardComponent,
    EventDetailsComponent,
    PlayerCardComponent,
    InfoComponent,
    CreateEventComponent,
    GeoSettingComponent,
    FilterModalComponent,
    PositionModalComponent,
    SettingsComponent,
    CityComponent,
    LinkComponent,
    NewsComponent,
    FieldComponent,
    NotificationComponent,
    InviteComponent,
    NotificationDetailComponent,
    MapComponent,
    WeekDayPipe,
    SelectComponent,
    DayPipe,
    GoogleplaceDirective,
    GroupModalComponent,
    UpdateUserModalComponent,
    FieldModalComponent,
    AddFieldModalComponent,
    FeatureFlagDirective,
    ButtonStateAnimationDirective,

    environment.production ? provideFirebaseApp(() => initializeApp(environment.firebaseConfig)) : [],
    environment.production ? provideAuth(() => getAuth()) : [],
    environment.production ? provideAnalytics(() => getAnalytics()) : [],
    // provideAppCheck(() => {
    //   // TODO get a reCAPTCHA Enterprise here https://console.cloud.google.com/security/recaptcha?project=_
    //   const provider = new ReCaptchaEnterpriseProvider(/* reCAPTCHA Enterprise site key */);
    //   return initializeAppCheck(undefined, { provider, isTokenAutoRefreshEnabled: true });
    // }),
    environment.production ? provideMessaging(() => getMessaging()) : [],
    environment.production ? providePerformance(() => getPerformance()) : [],
    environment.production ? provideStorage(() => getStorage()) : [],
    environment.production ? provideRemoteConfig(() => getRemoteConfig()) : [],
    RouterModule
  ],
  // ToDo: check for date locales
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeFeatureFlag,
      deps: [FeatureFlagService],
      multi: true
    },
    provideClientHydration()
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
