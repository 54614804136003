import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { errorMessages } from 'src/app/shared/constants';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'hb-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgClass,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatOptionModule,
    NgFor,
    KeyValuePipe
  ]
})
export class SelectComponent {
  @Input() control = new FormControl();
  @Input() options: string[] = [];
  @Input() label = 'Select';
  @Input() fieldClasses: string[] = [];
  @Input() fieldView: MatFormFieldAppearance = 'fill';
  @Input() withResetOption = false;
  @Input()
  set touched(val: boolean) {
    if (val) {
      this.control.markAsTouched();
      this.control.markAsDirty();
    }
  }

  errMsgs: any = errorMessages;
}
