<header class="top-bar flex-row justify-center">
  <h1 i18n class="page-label">Geo Setting</h1>
</header>
<section class="geo-setting-form" [formGroup]="geoSettingForm">
  <div class="flex-col space-between align-center gap-25">
    <h2 *hbFeatureFlag="true" i18n class="title">Please fill all required fields.</h2>
    <h2 *hbFeatureFlag="false" i18n class="title">
      Currently we are offering creation and participation in football games in Lviv.
    </h2>
    <mat-form-field appearance="fill" class="custom-mat-form-field">
      <mat-label class="mat-label" *hbFeatureFlag="true" i18n>Select Language</mat-label>
      <mat-label class="mat-label" *hbFeatureFlag="false" i18n>Language</mat-label>
      <mat-select formControlName="lang" panelClass="select-panel">
        @for (spName of languages; track spName.value) {
          <mat-option [value]="spName.value">
            {{ spName.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="custom-mat-form-field">
      <mat-label class="mat-label">City</mat-label>
      <input formControlName="city" matInput hbGooglePlace (setAddress)="setCityData($event)" name="city" />
    </mat-form-field>
    <button
      *hbFeatureFlag="true"
      class="outline-button"
      mat-button
      aria-label="Click here to get current location"
      i18n-aria-label
      (click)="getCurrentLocation()"
    >
      <mat-icon> location_on</mat-icon>
      <span class="va-middle" i18n>Get my current location</span>
    </button>
    <hb-hobby></hb-hobby>
  </div>
</section>
<footer class="submit-buttons flex-col space-between align-center gap-10">
  <button
    [disabled]="!geoSettingForm.valid"
    class="apply flex-auto fill-button"
    mat-raised-button
    aria-label="Apply settings"
    (click)="onClose()"
  >
    <span class="va-middle" i18n>Apply</span>
  </button>
  <p class="descriptive-link" i18n>
    We are also developing new features for other cities and hobbies. Interested in learning more about project? Visit
    <a class="link" href="https://hobbytostar.com" target="_blank">HobbyToStar</a> home page.
  </p>
</footer>
