<nav class="navbar mat-sidenav-container">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a routerLink="home" routerLinkActive="active-link" class="nav-link">
        <mat-icon svgIcon="globe"></mat-icon>
        <span class="link-text" i18n>Home</span>
      </a>
    </li>
    <li class="nav-item" *hbFeatureFlag="true">
      <a routerLink="notification" routerLinkActive="active-link" class="nav-link">
        <mat-icon svgIcon="notification"></mat-icon>
        <span class="link-text" i18n>Notifications</span>
      </a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['profile', loggedInUserId]" routerLinkActive="active-link" class="nav-link">
        <mat-icon svgIcon="account_box"></mat-icon>
        <span class="link-text" i18n>Profile</span>
      </a>
    </li>
    <li class="nav-item" *hbFeatureFlag="true">
      <a routerLink="settings" routerLinkActive="active-link" class="nav-link">
        <mat-icon class="settings-icon">settings</mat-icon>
        <span class="link-text" i18n>Settings</span>
      </a>
    </li>
    @if(isAdmin){
    <li class="nav-item">
      <a routerLink="admin" routerLinkActive="active-link" class="nav-link">
        <mat-icon class="settings-icon">A</mat-icon>
        <span class="link-text" i18n>Admin</span>
      </a>
    </li>
    }
  </ul>
</nav>
<main class="main-container">
  <section>
    <router-outlet></router-outlet>
  </section>
</main>
