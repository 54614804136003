<mat-form-field [appearance]="fieldView" [ngClass]="fieldClasses">
  <mat-label class="mat-label" i18n>{{ label }}</mat-label>
  <mat-select [formControl]="control">
    <mat-option *ngIf="withResetOption">None</mat-option>
    <mat-option [value]="opt" *ngFor="let opt of options">
      {{ opt }}
    </mat-option>
  </mat-select>
  <ng-container *ngIf="control.invalid && control.dirty && control.touched">
    <mat-error *ngFor="let err of control.errors | keyvalue">
      <div><sup>*</sup>{{ errMsgs[err.key] }}</div>
    </mat-error>
  </ng-container>
</mat-form-field>