@if (isUkraineUser() && loadMain) {
  <router-outlet></router-outlet>
} @else {
  <div class="text-center">
    <h1>Welcome to HobbyTS app</h1>
    <p>
      We are currently not working in your location, please visit our landing page
      <a href="https://hobbytostar.com/#questioner?userFrom=notUA">Hobbytostar</a> for more info.
    </p>
  </div>
}
