import { Component, OnInit } from '@angular/core';
import { ColorSchemeService } from '../services/theme-color-scheme.service';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FeatureFlagDirective } from '../shared/feature-flag.directive';
import { AuthService } from '../entry/auth/auth.service';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'hb-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, MatIconModule, RouterOutlet, FeatureFlagDirective]
})
export class CoreComponent implements OnInit {
  public loggedInUserId!: string | null;
  public isAdmin: boolean;
  private defaultTheme = 'dark';

  constructor(
    private themeColorService: ColorSchemeService,
    private authService: AuthService,
    private storageService: StorageService) {
      this.loggedInUserId =  this.storageService.getValue('store-userId');
      this.defaultTheme = this.themeColorService.currentActive();
  }

  public ngOnInit(): void {
    this.getUserRole();
  }

  public toggleTheme(): void {
    this.defaultTheme = this.defaultTheme === 'dark' ? 'light' : 'dark';
    this.themeColorService.update(this.defaultTheme);
  }

  private getUserRole(): void {
    this.isAdmin = this.authService.getCurrentUser()?.role === 'admin';
  }
}
